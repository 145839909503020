import React, { Component } from "react";
import { myFirebase } from "../firebase/firebase";
import { db } from "../firebase/firebase";
import { CSVLink } from "react-csv";
import CsvDownload from 'react-json-to-csv';
import { Link } from 'react-router-dom';
import EditableList from 'react-list-editable';
import 'react-list-editable/lib/react-list-editable.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import update from 'immutability-helper';


import Header from './Header';
import List from './DonationsList'
import DonationsList from "./DonationsList";

class Settings extends Component{
  constructor(props){
    super(props);
    console.log(props.location.test);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.Type = React.createRef();
    // this.state = {
    //   Donation2Projects: ['test', 'test2']
    // }
  }
  state = {
    uid: String,
    CharityID: String,
    Button1Amount: 3,
    Button2Amount: 5,
    Button3Amount: 10,
    Button4Amount: 15,
    Button5Amount: 20,
    Button6Amount: 30,
    Button7Amount: 50,
    Button8Amount: 100,
    CharityName: 'Enter a Charity Name',
    CharityNo: String,
    SumUpChargePercent: 1.69,
    DonateAmountHeader: String,
    DonatePageHeader: String,
    DonateText: String,
    GiftAidEnabled: true,
    HelpInfo: String,
    TxFeeShown: true,
    TempType: '',
    TempOption: '',
    Matches: [],
    Donation1Projects: [],
    Donation2Projects: [],
    Donation3Projects: [],
    Donation4Projects: [],
    Donation5Projects: [],
    Donation6Projects: [],
    Donation7Projects: [],
    Donation8Projects: [],
    Donation9Projects: [],
    Donation10Projects: [],
    MemberFeeAmount: 100,
    MemberFeeAmount1: 100,
    MemberFeeAmount2: 100,
    MemberFeeAmount3: 100,
    StudentFeeAmount: 100,
    StudentFeeAmount1: 100,
    StudentFeeAmount2: 100,
    StudentFeeAmount3: 100,
    MemberFeeGiftAidEnabled: true,
    MemberFeeRefMandatory: true,
    OptionalAdminFeeEnabled: true,
    OptionalAdminFixedAmount: 0,
    OptionalAdminPercent: 0,
    MemberFeeOptionalAdminFeeEnabled: true,
    MemberFeeOptionalAdminFixedAmount: 0,
    MemberFeeOptionalAdminPercent: 0,
    StudentFeeOptionalAdminFeeEnabled: true,
    StudentFeeOptionalAdminFixedAmount: 0,
    StudentFeeOptionalAdminPercent: 0
  };
  onLoad = (e) => {

  }

  componentDidMount(){
    const self = this;
    let user = myFirebase.auth().currentUser; 
    const docRef = db.collection('Users').doc(user.uid);
    docRef.get().then((doc) => {
      if (doc.exists) {
          let CharityID = doc.data().CharityID;
          this.setState({
            CharityID2: doc.data().CharityID2
          });
          db.collection('LicencedAccounts')
          .doc(CharityID)
          .get()
          .then(snapshot => {
            console.log(snapshot.data().MemberFeesEnabled)
            self.setState({
              LicenceType: snapshot.data().LicenceType,
              LicenceExpiry: snapshot.data().LicenceExpiry,
              MemberFeesEnabled: snapshot.data().MemberFeesEnabled,
              MultipleDonationTypes: snapshot.data().MultipleDonationTypes,
              StudentFeesEnabled: snapshot.data().StudentFeesEnabled
            })
          });
          db.collection('CharitySettings')
            .doc(CharityID)
            .get()
            .then(querySnapshot => {
              // console.log(querySnapshot.data());
              self.setState({
                CharityID: CharityID,
                Button1Amount: querySnapshot.data().Button1Amount,
                Button2Amount: querySnapshot.data().Button2Amount,
                Button3Amount: querySnapshot.data().Button3Amount,
                Button4Amount: querySnapshot.data().Button4Amount,
                Button5Amount: querySnapshot.data().Button5Amount,
                Button6Amount: querySnapshot.data().Button6Amount,
                Button7Amount: querySnapshot.data().Button7Amount,
                Button8Amount: querySnapshot.data().Button8Amount,
                CharityName: querySnapshot.data().CharityName,
                CharityNo: querySnapshot.data().CharityNo,
                DonateAmountHeader: querySnapshot.data().DonateAmountHeader,
                DonatePageHeader: querySnapshot.data().DonatePageHeader,
                DonateText: querySnapshot.data().DonateText,
                GiftAidEnabled: querySnapshot.data().GiftAidEnabled,
                HelpInfo: querySnapshot.data().HelpInfo,
                SuccesfulPaymentMessage: querySnapshot.data().SuccesfulPaymentMessage,
                SumUpChargePercent: querySnapshot.data().SumUpChargePercent,
                TxFeeCustomMessage: querySnapshot.data().TxFeeCustomMessage,
                TxFeeShown: querySnapshot.data().TxFeeShown,
                UnsuccesfulPaymentMessage: querySnapshot.data().UnsuccesfulPaymentMessage,
                OptionalAdminFeeEnabled: querySnapshot.data().OptionalAdminFeeEnabled,
                OptionalAdminFixedAmount: querySnapshot.data().OptionalAdminFixedAmount,
                OptionalAdminPercent: querySnapshot.data().OptionalAdminPercent,
                // Member Fee Amounts
                MemberFeeAmount: querySnapshot.data().MemberFeeAmount,
                MemberFeeDescription: querySnapshot.data().MemberFeeDescription,
                MemberFeeAmount1: querySnapshot.data().MemberFeeAmount1,
                MemberFeeDescription1: querySnapshot.data().MemberFeeDescription1,
                MemberFeeAmount2: querySnapshot.data().MemberFeeAmount2,
                MemberFeeDescription2: querySnapshot.data().MemberFeeDescription2,
                MemberFeeAmount3: querySnapshot.data().MemberFeeAmount3,
                MemberFeeDescription3: querySnapshot.data().MemberFeeDescription3,
                MemberFeeGiftAidEnabled: querySnapshot.data().MemberFeeGiftAidEnabled,
                MemberFeePassword: querySnapshot.data().MemberFeePassword,
                MemberFeeRefMandatory: querySnapshot.data().MemberFeeRefMandatory,
                MemberFeeTxFeeCustomMessage: querySnapshot.data().MemberFeeTxFeeCustomMessage,
                MemberFeeOptionalAdminFeeEnabled: querySnapshot.data().MemberFeeOptionalAdminFeeEnabled,
                MemberFeeOptionalAdminFixedAmount: querySnapshot.data().MemberFeeOptionalAdminFixedAmount,
                MemberFeeOptionalAdminPercent: querySnapshot.data().MemberFeeOptionalAdminPercent,
                // Student Fees
                StudentFeeAmount: querySnapshot.data().StudentFeeAmount,
                StudentFeeDescription: querySnapshot.data().StudentFeeDescription,
                StudentFeeAmount1: querySnapshot.data().StudentFeeAmount1,
                StudentFeeDescription1: querySnapshot.data().StudentFeeDescription1,
                StudentFeeAmount2: querySnapshot.data().StudentFeeAmount2,
                StudentFeeDescription2: querySnapshot.data().StudentFeeDescription2,
                StudentFeeAmount3: querySnapshot.data().StudentFeeAmount3,
                StudentFeeDescription3: querySnapshot.data().StudentFeeDescription3,
                StudentFeeModulePassword: querySnapshot.data().StudentFeeModulePassword,
                StudentFeeTxFeeCustomMessage: querySnapshot.data().StudentFeeTxFeeCustomMessage,
                StudentFeeOptionalAdminFeeEnabled: querySnapshot.data().StudentFeeOptionalAdminFeeEnabled,
                StudentFeeOptionalAdminFixedAmount: querySnapshot.data().StudentFeeOptionalAdminFixedAmount,
                StudentFeeOptionalAdminPercent: querySnapshot.data().StudentFeeOptionalAdminPercent,
                // Donation Types
                Donation1Type: querySnapshot.data().Donation1Type,
                Donation2Type: querySnapshot.data().Donation2Type,
                Donation3Type: querySnapshot.data().Donation3Type,
                Donation4Type: querySnapshot.data().Donation4Type,
                Donation5Type: querySnapshot.data().Donation5Type,
                Donation6Type: querySnapshot.data().Donation6Type,
                Donation7Type: querySnapshot.data().Donation7Type,
                Donation8Type: querySnapshot.data().Donation8Type,
                Donation9Type: querySnapshot.data().Donation9Type,
                Donation10Type: querySnapshot.data().Donation10Type,
                Donation2Projects: querySnapshot.data().Donation2Projects,
                Donation3Projects: querySnapshot.data().Donation3Projects,
                Donation4Projects: querySnapshot.data().Donation4Projects,
                Donation5Projects: querySnapshot.data().Donation5Projects,
                Donation6Projects: querySnapshot.data().Donation6Projects,
                Donation7Projects: querySnapshot.data().Donation7Projects,
                Donation8Projects: querySnapshot.data().Donation8Projects,
                Donation9Projects: querySnapshot.data().Donation9Projects,
                Donation10Projects: querySnapshot.data().Donation10Projects,
              });
              if(querySnapshot.data().Donation1Projects){
                let list = querySnapshot.data().Donation1Projects;
                let newList = [];
                self.setState({
                  Donation1Projects: querySnapshot.data().Donation1Projects
                });
                for (var i = 0; i < list.length; i++) {
                 var main = list[i].replace(']', '').split('[');
                 if(main.length > 1){
                  var type = main[0];
                  var option = main[1];
                 } else{
                  var type = main[0];
                  var option = '';
                 }
                 newList.push({"Type": type, "Option": option})
                 self.setState({
                   Donation1ProjectOptions: newList
                 })
                }
                // console.log(newList)
              } else{
                self.setState({
                  Donation1Projects: []
                })
              }
              // this.setState({ Matches });
          });
      } else {
          // doc.data() will be undefined in this case
          self.setState({ data: null });
          console.log("No such document!");
      }
    }).catch(function (error) {
      self.setState({ data: null });
        console.log("Error getting document:", error);
    });
    // const docRef = db.collection('Users').doc(user.uid);
    // this.setState({uid: user.uid});
  }

  componentWillUnmount(){

  }

  updateSettings(){
    // console.log(this.state.GiftAidEnabled)
    db.collection('CharitySettings')
      .doc(this.state.CharityID)
      .update({
        Button1Amount: Number(this.state.Button1Amount),
        Button2Amount: Number(this.state.Button2Amount),
        Button3Amount: Number(this.state.Button3Amount),
        Button4Amount: Number(this.state.Button4Amount),
        Button5Amount: Number(this.state.Button5Amount),
        Button6Amount: Number(this.state.Button6Amount),
        Button7Amount: Number(this.state.Button7Amount),
        Button8Amount: Number(this.state.Button8Amount),
        CharityName: String(this.state.CharityName),
        CharityNo: String(this.state.CharityNo),
        DonateAmountHeader: String(this.state.DonateAmountHeader),
        DonatePageHeader: String(this.state.DonatePageHeader),
        DonateText: String(this.state.DonateText),
        DonatePageHeader: String(this.state.DonatePageHeader),
        GiftAidEnabled: Boolean(this.state.GiftAidEnabled),
        HelpInfo: String(this.state.HelpInfo),
        SuccesfulPaymentMessage: String(this.state.SuccesfulPaymentMessage),
        SumUpChargePercent: Number(this.state.SumUpChargePercent),
        TxFeeCustomMessage: String(this.state.TxFeeCustomMessage),
        TxFeeShown: Boolean(this.state.TxFeeShown),
        UnsuccesfulPaymentMessage: String(this.state.UnsuccesfulPaymentMessage),
        OptionalAdminFeeEnabled: Boolean(this.state.OptionalAdminFeeEnabled),
        OptionalAdminFixedAmount: Number(this.state.OptionalAdminFixedAmount),
        OptionalAdminPercent: Number(this.state.OptionalAdminPercent),
      });
      alert('Your app settings have been updated');
  }

  updateDonationSettings(){
    const self = this;
    var list = this.state.Donation1ProjectOptions;
    var newList = [];
    if(list){
      for (var i = 0; i < list.length; i++) {
        var project = list[i].Type;
        var option = list[i].Option;
        var joint = project + ' [' + option + ']';
        newList.push(joint);
      }
    }
    console.log(newList)
    console.log(this.state.Donation1Projects)
    // console.log(this.state.GiftAidEnabled)
    db.collection('CharitySettings')
      .doc(this.state.CharityID)
      .update({
        Donation1Type: String(this.state.Donation1Type),
        Donation2Type: String(this.state.Donation2Type),
        Donation3Type: String(this.state.Donation3Type),
        Donation4Type: String(this.state.Donation4Type),
        Donation5Type: String(this.state.Donation5Type),
        Donation6Type: String(this.state.Donation6Type),
        Donation7Type: String(this.state.Donation7Type),
        Donation8Type: String(this.state.Donation8Type),
        Donation9Type: String(this.state.Donation9Type),
        Donation10Type: String(this.state.Donation10Type),
        // Donation1Projects: newList
      })
      alert('Your donation type settings have been updated');
  }

  updateSettingsMembers(){
    db.collection('CharitySettings')
    .doc(this.state.CharityID)
    .update({
      MemberFeeAmount: Number(this.state.MemberFeeAmount),
      MemberFeeDescription: String(this.state.MemberFeeDescription),
      MemberFeeAmount1: Number(this.state.MemberFeeAmount1),
      MemberFeeDescription1: String(this.state.MemberFeeDescription1),
      MemberFeeAmount2: Number(this.state.MemberFeeAmount2),
      MemberFeeDescription2: String(this.state.MemberFeeDescription2),
      MemberFeeAmount3: Number(this.state.MemberFeeAmount3),
      MemberFeeDescription3: String(this.state.MemberFeeDescription3),
      MemberFeeGiftAidEnabled: Boolean(this.state.MemberFeeGiftAidEnabled),
      MemberFeePassword: String(this.state.MemberFeePassword),
      MemberFeeRefMandatory: Boolean(this.state.MemberFeeRefMandatory),
      MemberFeeTxFeeCustomMessage: String(this.state.MemberFeeTxFeeCustomMessage),
      MemberFeeOptionalAdminFeeEnabled: Boolean(this.state.MemberFeeOptionalAdminFeeEnabled),
      MemberFeeOptionalAdminFixedAmount: Number(this.state.MemberFeeOptionalAdminFixedAmount),
      MemberFeeOptionalAdminPercent: Number(this.state.MemberFeeOptionalAdminPercent),
    })
    alert('Your member fee settings have been updated');
  }

  updateSettingsStudent(){
    db.collection('CharitySettings')
    .doc(this.state.CharityID)
    .update({
      StudentFeeAmount: Number(this.state.StudentFeeAmount),
      StudentFeeDescription: String(this.state.StudentFeeDescription),
      StudentFeeAmount1: Number(this.state.StudentFeeAmount1),
      StudentFeeDescription1: String(this.state.StudentFeeDescription1),
      StudentFeeAmount2: Number(this.state.StudentFeeAmount2),
      StudentFeeDescription2: String(this.state.StudentFeeDescription2),
      StudentFeeAmount3: Number(this.state.StudentFeeAmount3),
      StudentFeeDescription3: String(this.state.StudentFeeDescription3),
      StudentFeeModulePassword: String(this.state.StudentFeeModulePassword),
      StudentFeeTxFeeCustomMessage: String(this.state.StudentFeeTxFeeCustomMessage),
      StudentFeeOptionalAdminFeeEnabled: Boolean(this.state.StudentFeeOptionalAdminFeeEnabled),
      StudentFeeOptionalAdminFixedAmount: Number(this.state.StudentFeeOptionalAdminFixedAmount),
      StudentFeeOptionalAdminPercent: Number(this.state.StudentFeeOptionalAdminPercent),
    })
    alert('Your student fee settings have been updated');
  }

  handleCheckboxChange(event) {
    // Use to replace all repeated code g
    this.setState({[event.target.id]: event.target.checked});
  }

  onListChange(number, newList) {
    // if(newList.length > 10){
    //   newList.splice(-1, 1)
    //   alert('Limit is 10 items')
    // }
    switch(number){
      case 1:
        this.setState({
          Donation1rojects: newList
        });
        db.collection('CharitySettings')
        .doc(this.state.CharityID)
        .update({
          Donation1Projects: newList
        })
        break;
      case 2:
        this.setState({
          Donation2Projects: newList
        });
        db.collection('CharitySettings')
        .doc(this.state.CharityID)
        .update({
          Donation2Projects: newList
        })
        break;
      case 3:
        this.setState({
          Donation3Projects: newList
        });
        db.collection('CharitySettings')
        .doc(this.state.CharityID)
        .update({
          Donation3Projects: newList
        })
        break;
      case 4:
        this.setState({
          Donation4Projects: newList
        });
        db.collection('CharitySettings')
        .doc(this.state.CharityID)
        .update({
          Donation4Projects: newList
        })
        break;
      case 5:
        this.setState({
          Donation5Projects: newList
        });
        db.collection('CharitySettings')
        .doc(this.state.CharityID)
        .update({
          Donation5Projects: newList
        })
        break;
      case 6:
        this.setState({
          Donation6Projects: newList
        });
        db.collection('CharitySettings')
        .doc(this.state.CharityID)
        .update({
          Donation6Projects: newList
        })
        break;
      case 7:
        this.setState({
          Donation7Projects: newList
        });
        db.collection('CharitySettings')
        .doc(this.state.CharityID)
        .update({
          Donation7Projects: newList
        })
        break;
      case 8:
        this.setState({
          Donation8Projects: newList
        });
        db.collection('CharitySettings')
        .doc(this.state.CharityID)
        .update({
          Donation8Projects: newList
        })
        break;
      case 9:
        this.setState({
          Donation9Projects: newList
        });
        db.collection('CharitySettings')
        .doc(this.state.CharityID)
        .update({
          Donation9Projects: newList
        })
        break;
      case 10:
        this.setState({
          Donation10Projects: newList
        });
        db.collection('CharitySettings')
        .doc(this.state.CharityID)
        .update({
          Donation10Projects: newList
        })
        break;
    }
  }

  removeItem(index) {
    this.setState({
      Donation1ProjectOptions: this.state.Donation1ProjectOptions.filter((_, i) => i !== index)
    });
  }

  updateItem(index){
    this.setState({
      Donation1ProjectOptions: update(this.state.Donation1ProjectOptions, {[index]: {Type: {$set: 'updated field name'}}})
      // Donation1ProjectOptions : update(this.state.Donation1ProjectOptions, {$push: [4]}); // => [1, 2, 3, 4]
    })
  }

  addItem(ap, event){
    const dt = ap;
    const rowObj = {
      Type: this.state.TempType,
      Option: this.state.TempOption
    };
    if(this.state.TempType === ""){
      alert('Please make sure the project field is filled');
    } else{
      this.setState({ 
        [dt]: update(this.state[dt], 
              { $push : [rowObj] } 
          ) 
      })
    }
    event.preventDefault();
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    // alert('A name was submitted: ' + this.Type.current.value);
    event.preventDefault();
  }

  render() {
    return (
      <div id="settings">
        <Header />
        <Container fixed>
          <h1>Kiosk/App Settings</h1>
          <p>Edit your settings here.</p>
          {/* {this.state.CharityID2 &&
              <Link to={{
                pathname: '/settings',
                test: {
                  name: 'Test'
                }
              }} onClick={this.forceUpdate}>
                Switch Account
              </Link>
            } */}
        <Tabs>
          <TabList>
            <Tab>Settings</Tab>
            {this.state.MemberFeesEnabled &&
              <Tab>Member Fee Settings</Tab>
            }
            {this.state.StudentFeesEnabled &&
              <Tab>Student Fee Settings</Tab>
            }
            {this.state.MultipleDonationTypes &&
              <Tab>Donation Types Settings</Tab>
            }
          </TabList>

    <TabPanel>
    <div className="settings">
     <h2>Settings</h2>   
    <h3>General</h3>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Charity Name"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.CharityName}
              variant="outlined"
              onChange={event => this.setState({CharityName: event.target.value })}
            />
            <p>Enter you charity name.</p>
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Charity No."
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.CharityNo}
              variant="outlined"
              onChange={event => this.setState({CharityNo: event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Sumup Percentage"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.SumUpChargePercent}
              variant="outlined"
              onChange={event => this.setState({SumUpChargePercent  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <label for="GiftAidEnabled">Gift Aid Enabled</label>
            <Checkbox
            id="GiftAidEnabled"
              checked = {this.state.GiftAidEnabled}
              onChange={this.handleCheckboxChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          <div className="input-group">
            <label for="TxFeeShown">Show Transaction Fee</label>
            <Checkbox
              id="TxFeeShown"
              checked = {this.state.TxFeeShown}
              onChange={this.handleCheckboxChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          <div className="input-group">
            <label for="OptionalAdminFeeEnabled">Optional Admin Fees Enabled</label>
            <Checkbox
            id="OptionalAdminFeeEnabled"
              checked = {this.state.OptionalAdminFeeEnabled}
              onChange={this.handleCheckboxChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          <div className="input-group">
              <TextField
                id="outlined-number"
                label="Optional Admin Fixed Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                value = {this.state.OptionalAdminFixedAmount}
                variant="outlined"
                onChange={event => this.setState({OptionalAdminFixedAmount: event.target.value })}
              />
          </div>
          <div className="input-group">
              <TextField
                id="outlined-number"
                label="Optional Admin Percent"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value = {this.state.OptionalAdminPercent}
                variant="outlined"
                onChange={event => this.setState({OptionalAdminPercent: event.target.value })}
              />
          </div>
          <br></br><br></br>
          <hr></hr>
          <br></br><br></br>
          <h3>Donations Page</h3>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Donation Page Header"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.DonatePageHeader}
              variant="outlined"
              onChange={event => this.setState({DonatePageHeader  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
                id="outlined-multiline-static"
                label="Donate Text"
                multiline
                rows={8}
                value = {this.state.DonateText}
                variant="outlined"
                onChange={event => this.setState({DonateText: event.target.value })}
              />
          </div>
          <div className="input-group">

            <TextField
                id="outlined-multilisne-static"
                label="Transaction Fee Message"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                rows={12}
                value = {this.state.TxFeeCustomMessage}
                variant="outlined"
                onChange={event => this.setState({TxFeeCustomMessage: event.target.value })}
              />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Donation Amounts Header"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.DonateAmountHeader}
              variant="outlined"
              onChange={event => this.setState({DonateAmountHeader  : event.target.value })}
            />
          </div>
       <h3>Button Amounts</h3>
          <div className="buttons">
            <div className="input-group">
              <TextField
                id="outlined-number"
                label="Button 1 Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value = {this.state.Button1Amount}
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                // helperText="This is an example hint."
                onChange={event => this.setState({Button1Amount: event.target.value })}
              />
            </div>
            <div className="input-group">
              <TextField
                id="outlined-number"
                label="Button 2 Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value = {this.state.Button2Amount}
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                onChange={event => this.setState({Button2Amount: event.target.value })}
              />
            </div>
            <div className="input-group">
              <TextField
                id="outlined-number"
                label="Button 3 Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                value = {this.state.Button3Amount}
                variant="outlined"
                onChange={event => this.setState({Button3Amount: event.target.value })}
              />
            </div>
            <div className="input-group">
              <TextField
                id="outlined-number"
                label="Button 4 Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                value = {this.state.Button4Amount}
                variant="outlined"
                onChange={event => this.setState({Button4Amount: event.target.value })}
              />
            </div>
            <div className="input-group">
              <TextField
                id="outlined-number"
                label="Button 5 Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                value = {this.state.Button5Amount}
                variant="outlined"
                onChange={event => this.setState({Button5Amount: event.target.value })}
              />
            </div>
            <div className="input-group">
              <TextField
                id="outlined-number"
                label="Button 6 Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                value = {this.state.Button6Amount}
                variant="outlined"
                onChange={event => this.setState({Button6Amount: event.target.value })}
              />
            </div>
            <div className="input-group">
              <TextField
                id="outlined-number"
                label="Button 7 Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                value = {this.state.Button7Amount}
                variant="outlined"
                onChange={event => this.setState({Button7Amount: event.target.value })}
              />
            </div>
            <div className="input-group">
              <TextField
                id="outlined-number"
                label="Button 8 Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                value = {this.state.Button8Amount}
                variant="outlined"
                onChange={event => this.setState({Button8Amount: event.target.value })}
              />
            </div>
          <br></br><br></br>
          <hr></hr>
          <br></br><br></br>
          <h3>Additional Messaging</h3>
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Help Information"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.HelpInfo}
              variant="outlined"
              onChange={event => this.setState({HelpInfo  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Successful Payment Message"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.SuccesfulPaymentMessage}
              variant="outlined"
              onChange={event => this.setState({SuccesfulPaymentMessage  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
                id="outlined-multilisne-static"
                label="Unsuccessful Payment Message"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                rows={8}
                value = {this.state.UnsuccesfulPaymentMessage}
                variant="outlined"
                onChange={event => this.setState({UnsuccesfulPaymentMessage: event.target.value })}
              />
          </div>
          <div className="input-group">
                <Button disableElevation variant="contained" size="medium" style={{background: '#0d5d46', color: '#fff'}} onClick={() => this.updateSettings()}>
                  Update App Settings
                </Button>
          </div>
        </div>
    </TabPanel>
    {this.state.MemberFeesEnabled &&
      <TabPanel>
        <div className="settings">
        <h2>Member Fee Settings</h2>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Password"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.MemberFeePassword}
              variant="outlined"
              onChange={event => this.setState({MemberFeePassword  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Member Fee Amount 1"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.MemberFeeAmount}
              variant="outlined"
              onChange={event => this.setState({MemberFeeAmount  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Member Receipt Description 1"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.MemberFeeDescription}
              variant="outlined"
              onChange={event => this.setState({MemberFeeDescription  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Member Fee Amount 2"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.MemberFeeAmount1}
              variant="outlined"
              onChange={event => this.setState({MemberFeeAmount1  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Member Receipt Description 2"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.MemberFeeDescription1}
              variant="outlined"
              onChange={event => this.setState({MemberFeeDescription1  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Member Fee Amount 3"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.MemberFeeAmount2}
              variant="outlined"
              onChange={event => this.setState({MemberFeeAmount2  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Member Receipt Description 3"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.MemberFeeDescription2}
              variant="outlined"
              onChange={event => this.setState({MemberFeeDescription2  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Member Fee Amount 4"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.MemberFeeAmount3}
              variant="outlined"
              onChange={event => this.setState({MemberFeeAmount3  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Member Receipt Description 4"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.MemberFeeDescription3}
              variant="outlined"
              onChange={event => this.setState({MemberFeeDescription3  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
                id="outlined-multilisne-static"
                label="Transactions Fee Custom Message"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                rows={12}
                value = {this.state.MemberFeeTxFeeCustomMessage}
                variant="outlined"
                onChange={event => this.setState({MemberFeeTxFeeCustomMessage: event.target.value })}
              />
          </div>
          <div className="input-group">
            <label for="MemberFeeGiftAidEnabled">Enable Gift Aid for Fee</label>
            <Checkbox
            id="MemberFeeGiftAidEnabled"
              checked = {this.state.MemberFeeGiftAidEnabled}
              onChange={this.handleCheckboxChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          <div className="input-group">
            <label for="MemberFeeRefMandatory">Fee Reference Mandatory</label>
            <Checkbox
            id="MemberFeeRefMandatory"
              checked = {this.state.MemberFeeRefMandatory}
              onChange={this.handleCheckboxChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          <div className="input-group">
            <label for="MemberFeeOptionalAdminFeeEnabled">Member Fee Optional Admin Fee Enabled</label>
            <Checkbox
            id="MemberFeeOptionalAdminFeeEnabled"
              checked = {this.state.MemberFeeOptionalAdminFeeEnabled}
              onChange={this.handleCheckboxChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          <div className="input-group">
              <TextField
                id="outlined-number"
                label="Member Fee Optional Admin Fixed Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                value = {this.state.MemberFeeOptionalAdminFixedAmount}
                variant="outlined"
                onChange={event => this.setState({MemberFeeOptionalAdminFixedAmount: event.target.value })}
              />
          </div>
          <div className="input-group">
              <TextField
                id="outlined-number"
                label="Member Fee Optional Admin Percent"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value = {this.state.MemberFeeOptionalAdminPercent}
                variant="outlined"
                onChange={event => this.setState({MemberFeeOptionalAdminPercent: event.target.value })}
              />
          </div>
            <div className="input-group">
                <Button disableElevation variant="contained" size="medium" style={{background: '#0d5d46', color: '#fff'}} onClick={() => this.updateSettingsMembers()}>
                  Update Fee Settings
                </Button>
            </div>
        </div>
      </TabPanel>
    }
{this.state.StudentFeesEnabled &&
      <TabPanel>
        <div className="settings">
        <h2>Student Fee Settings</h2>
        <div className="input-group">
            <TextField
              id="outlined-number"
              label="Password"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.StudentFeeModulePassword}
              variant="outlined"
              onChange={event => this.setState({StudentFeeModulePassword  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Student Fee Amount 1"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.StudentFeeAmount}
              variant="outlined"
              onChange={event => this.setState({StudentFeeAmount  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Student Receipt Description 1"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.StudentFeeDescription}
              variant="outlined"
              onChange={event => this.setState({StudentFeeDescription  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Student Fee Amount 2"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.StudentFeeAmount1}
              variant="outlined"
              onChange={event => this.setState({StudentFeeAmount1  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Member Receipt Description 2"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.StudentFeeDescription1}
              variant="outlined"
              onChange={event => this.setState({StudentFeeDescription1  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Student Fee Amount 3"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.StudentFeeAmount2}
              variant="outlined"
              onChange={event => this.setState({StudentFeeAmount2  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Member Receipt Description 3"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.StudentFeeDescription2}
              variant="outlined"
              onChange={event => this.setState({StudentFeeDescription2  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Student Fee Amount 4"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.StudentFeeAmount3}
              variant="outlined"
              onChange={event => this.setState({StudentFeeAmount3  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
              id="outlined-number"
              label="Student Receipt Description 4"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.StudentFeeDescription3}
              variant="outlined"
              onChange={event => this.setState({StudentFeeDescription3  : event.target.value })}
            />
          </div>
          <div className="input-group">
            <TextField
                id="outlined-multilisne-static"
                label="Transactions Fee Custom Message"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                rows={12}
                value = {this.state.StudentFeeTxFeeCustomMessage}
                variant="outlined"
                onChange={event => this.setState({StudentFeeTxFeeCustomMessage: event.target.value })}
              />
          </div>
          <div className="input-group">
            <label for="MemberFeeOptionalAdminFeeEnabled">Student Fee Optional Admin Fee Enabled</label>
            <Checkbox
            id="StudentFeeOptionalAdminFeeEnabled"
              checked = {this.state.StudentFeeOptionalAdminFeeEnabled}
              onChange={this.handleCheckboxChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          <div className="input-group">
              <TextField
                id="outlined-number"
                label="Student Fee Optional Admin Fixed Amount"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                value = {this.state.StudentFeeOptionalAdminFixedAmount}
                variant="outlined"
                onChange={event => this.setState({StudentFeeOptionalAdminFixedAmount: event.target.value })}
              />
          </div>
          <div className="input-group">
              <TextField
                id="outlined-number"
                label="Student Fee Optional Admin Percent"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value = {this.state.StudentFeeOptionalAdminPercent}
                variant="outlined"
                onChange={event => this.setState({StudentFeeOptionalAdminPercent: event.target.value })}
              />
          </div>
            <div className="input-group">
                <Button disableElevation variant="contained" size="medium" style={{background: '#0d5d46', color: '#fff'}} onClick={() => this.updateSettingsStudent()}>
                  Update Student Fee Settings
                </Button>
            </div>
        </div>
      </TabPanel>
    }
    {this.state.MultipleDonationTypes &&
      <TabPanel>
        <div className="settings donationtype">
          <h2>Donation Type Settings</h2>
          <div className="input-group">
            <h4>Donation Type 1 and Projects</h4>
            <TextField
              id="outlined-number"
              label="Donation 1 Type"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.Donation1Type}
              variant="outlined"
              onChange={event => this.setState({Donation1Type  : event.target.value })}
            />
            { this.state && this.state.Donation1Projects &&
              <div>
                <EditableList
                list={this.state.Donation1Projects}
                onListChange={this.onListChange.bind(this, 1)}
                placeholder='Enter a value'
                />
              </div>
            }
          </div>
          <div className="input-group">
            <h4>Donation Type 2 and Projects</h4>
            <TextField
              id="outlined-number"
              label="Donation 2 Type"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.Donation2Type}
              variant="outlined"
              onChange={event => this.setState({Donation2Type  : event.target.value })}
            />
            { this.state && this.state.Donation2Projects &&
              <div>
                <EditableList
                list={this.state.Donation2Projects}
                onListChange={this.onListChange.bind(this, 2)}
                placeholder='Enter a value'
                />
              </div>
            }
          </div>
          <div className="input-group">
            <h4>Donation Type 3 and Projects</h4>
            <TextField
              id="outlined-number"
              label="Donation 3 Type"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.Donation3Type}
              variant="outlined"
              onChange={event => this.setState({Donation3Type  : event.target.value })}
            />
            { this.state && this.state.Donation3Projects &&
              <div>
                <EditableList
                list={this.state.Donation3Projects}
                onListChange={this.onListChange.bind(this, 3)}
                placeholder='Enter a value'
                />
              </div>
            }
          </div>
          <div className="input-group">
            <h4>Donation Type 4 and Projects</h4>
            <TextField
              id="outlined-number"
              label="Donation 4 Type"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.Donation4Type}
              variant="outlined"
              onChange={event => this.setState({Donation4Type  : event.target.value })}
            />
            { this.state && this.state.Donation4Projects &&
              <div>
                <EditableList
                list={this.state.Donation4Projects}
                onListChange={this.onListChange.bind(this, 4)}
                placeholder='Enter a value'
                />
              </div>
            }
          </div>
          <div className="input-group">
            <h4>Donation Type 5 and Projects</h4>
            <TextField
              id="outlined-number"
              label="Donation 5 Type"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.Donation5Type}
              variant="outlined"
              onChange={event => this.setState({Donation5Type  : event.target.value })}
            />
            { this.state && this.state.Donation5Projects &&
              <div>
                <EditableList
                list={this.state.Donation5Projects}
                onListChange={this.onListChange.bind(this, 5)}
                placeholder='Enter a value'
                />
              </div>
            }
          </div>
          <div className="input-group">
            <h4>Donation Type 6 and Projects</h4>
            <TextField
              id="outlined-number"
              label="Donation 6 Type"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.Donation6Type}
              variant="outlined"
              onChange={event => this.setState({Donation6Type  : event.target.value })}
            />
            { this.state && this.state.Donation6Projects &&
              <div>
                <EditableList
                list={this.state.Donation6Projects}
                onListChange={this.onListChange.bind(this, 6)}
                placeholder='Enter a value'
                />
              </div>
            }
          </div>
          <div className="input-group">
            <h4>Donation Type 7 and Projects</h4>
            <TextField
              id="outlined-number"
              label="Donation 7 Type"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.Donation7Type}
              variant="outlined"
              onChange={event => this.setState({Donation7Type  : event.target.value })}
            />
            { this.state && this.state.Donation7Projects &&
              <div>
                <EditableList
                list={this.state.Donation7Projects}
                onListChange={this.onListChange.bind(this, 7)}
                placeholder='Enter a value'
                />
              </div>
            }
          </div>
          <div className="input-group">
            <h4>Donation Type 8 and Projects</h4>
            <TextField
              id="outlined-number"
              label="Donation 8 Type"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.Donation8Type}
              variant="outlined"
              onChange={event => this.setState({Donation8Type  : event.target.value })}
            />
            { this.state && this.state.Donation8Projects &&
              <div>
                <EditableList
                list={this.state.Donation8Projects}
                onListChange={this.onListChange.bind(this, 8)}
                placeholder='Enter a value'
                />
              </div>
            }
          </div>
          <div className="input-group">
            <h4>Donation Type 9 and Projects</h4>
            <TextField
              id="outlined-number"
              label="Donation 9 Type"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.Donation9Type}
              variant="outlined"
              onChange={event => this.setState({Donation9Type  : event.target.value })}
            />
            { this.state && this.state.Donation9Projects &&
              <div>
                <EditableList
                list={this.state.Donation9Projects}
                onListChange={this.onListChange.bind(this, 9)}
                placeholder='Enter a value'
                />
              </div>
            }
          </div>
          <div className="input-group">
            <h4>Donation Type 10 and Projects</h4>
            <TextField
              id="outlined-number"
              label="Donation 10 Type"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              value = {this.state.Donation10Type}
              variant="outlined"
              onChange={event => this.setState({Donation10Type  : event.target.value })}
            />
            { this.state && this.state.Donation10Projects &&
              <div>
                <EditableList
                list={this.state.Donation10Projects}
                onListChange={this.onListChange.bind(this, 10)}
                placeholder='Enter a value'
                />
              </div>
            }
          </div>
          <div className="input-group">
            <Button disableElevation variant="contained" size="medium" style={{background: '#0d5d46', color: '#fff'}} onClick={() => this.updateDonationSettings()}>
              Update Donation Types
            </Button>
            {/* <div>
              {this.state.Donation1ProjectOptions.map((item, index) => (
                <li key={index}>
                  <label>Project: {item.Type}</label>
                  <label>Option: {item.Option}</label><br></br>
                  <button type="button" onClick={() => this.removeItem(index)}>
                    Remove
                  </button>
                  <button type="button" onClick={() => this.updateItem(index)}>
                    upd
                  </button>
                </li>
              ))}
              {this.state.TempType}
              <div>
                <input type="text" onChange={event => this.setState({TempType  : event.target.value })} />
                <input type="text" onChange={event => this.setState({TempOption  : event.target.value })} />
                <button type="submit" onClick={this.addItem.bind(this, 'Donation1ProjectOptions')}>Add Item</button>
              </div>
            </div>
              <DonationsList />
              <form onSubmit={this.handleSubmit}>
                <label>
                  Type:
                  <input type="text" ref={(input) => this.Type = input}/>
                </label>
                <label>
                  Option:
                  <input type="text" ref={(input) => this.Option = input}/>
                </label>
                <input type="submit" value="Submit" />
              </form> */}
            </div>
        </div>
      </TabPanel>
    }
  </Tabs>
        </Container>
        
      <CssBaseline />
      </div>
      
    );
  }

}

export default Settings;
