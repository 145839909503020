import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBVSkWTjoRB8QL44yBvpIWQS2m2bDAg2ns",
  authDomain: "easydonate-20a31.firebaseapp.com",
  databaseURL: "https://easydonate-20a31.firebaseio.com",
  projectId: "easydonate-20a31",
  storageBucket: "easydonate-20a31.appspot.com",
  messagingSenderId: "523825734705",
  appId: "1:523825734705:web:87dc0da472fca5b9abe110",
  measurementId: "G-HV7MMNCKPR"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;

firebase.firestore().enablePersistence()
.catch(function(err) {
    console.log(err);
    if (err.code == 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code == 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
});
