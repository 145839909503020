import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { loginUser } from "../actions";
import { withStyles } from "@material-ui/styles";
import { myFirebase } from "../firebase/firebase";
import { db } from "../firebase/firebase";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import logo from '../logo.png';
const styles = () => ({
  "@global": {
    body: {
      // backgroundColor: "#fff"
    }
  },
  paper: {
    marginTop: 100,
    display: "flex",
    padding: 20,
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f50057"
  },
  form: {
    marginTop: 1
  },
  errorText: {
    color: "#f50057",
    marginBottom: 5,
    textAlign: "center"
  }
});

class Login extends Component {
  constructor(props){
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this);
    this.passForgot = this.passForgot.bind(this)
    this.keyPressed = this.keyPressed.bind(this);
  }
  state = { email: "", password: "" };

  handleEmailChange = ({ target }) => {
    this.setState({ email: target.value });
  };

  handlePasswordChange = ({ target }) => {
    this.setState({ password: target.value });
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { email, password } = this.state;

    dispatch(loginUser(email+'@easydonate.uk', password));
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      this.handleSubmit()
    }
  }

  passForgot(){
    var auth = myFirebase.auth();
    var emailAddress = "hanif.d@live.co.uk";
    
    auth.sendPasswordResetEmail(emailAddress).then(function() {
      // Email sent.
      console.log('SENT');
    }).catch(function(error) {
      // An error happened.
    });
  }

  render() {
    const { classes, loginError, isAuthenticated } = this.props;
    if (isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return (
        <Container component="main" maxWidth="xs">
          <Paper className={classes.paper}>
            {/* <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar> */}
            <img src={logo} width="120" style={{margin: '2rem 0'}}/>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Username"
              name="email"
              onChange={this.handleEmailChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onKeyPress={this.keyPressed}
              onChange={this.handlePasswordChange}
            />
            {loginError && (
              <Typography component="p" className={classes.errorText}>
                Incorrect email or password.
              </Typography>
            )}
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={this.handleSubmit}
              style={{background: '#0d5d46', color: '#fff', marginTop: '1rem'}}
            >
              Sign In
            </Button>
            {/* <Button disableElevation style={{marginTop: '1rem'}} size="medium" href="/forgotten-password">
                  Forgot your password?
            </Button> */}
          </Paper>
        </Container>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Login));
