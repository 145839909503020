import React, { Component } from "react";
import { myFirebase } from "../firebase/firebase";
import { db } from "../firebase/firebase";
import { CSVLink } from "react-csv";
import CsvDownload from 'react-json-to-csv'
import moment from "moment"
import lodash from 'lodash'
import transitions from "@material-ui/core/styles/transitions";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { isCompositeComponent } from "react-dom/test-utils";

var _ = require('lodash');

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class Transactions extends Component{
  constructor(props){
    super(props);
    
  }
  state = {
    Matches: [],
    groupedByMonth: [],
    headers: [
      { label: "id", key: "id" },
    ]
  };


  onLoad = (e) => {

  }

  componentDidMount(){
    let user = myFirebase.auth().currentUser;    
  // Subsequent queries will use persistence, i
    const docRef = db.collection('Users').doc(user.uid);
    docRef.get().then((doc) => {
        if (doc.exists) {
            let CharityID = doc.data().CharityID;
            this.setState({ CharityID : CharityID });
            db.collection('LicencedAccounts')
            .doc(CharityID)
            .get()
            .then(snapshot => {
              console.log(snapshot.data().MemberFeesEnabled)
              this.setState({
                LicenceType: snapshot.data().LicenceType,
                LicenceExpiry: snapshot.data().LicenceExpiry,
                MemberFeesEnabled: snapshot.data().MemberFeesEnabled,
                MultipleDonationTypes: snapshot.data().MultipleDonationTypes,
                StudentFeesEnabled: snapshot.data().StudentFeesEnabled
              })
            });
            db.collection("Transactions")
              // .collection("Transactions")
              .doc('Charities')
              .collection(CharityID)
              // .limit(30)
              .get()
              .then(querySnapshot => {
                  const Matches = [];
                  var TransactionCode; 
                  querySnapshot.docs.forEach(doc => {
                    if(doc.data()["TransactionCode "]){
                        TransactionCode = doc.data()["TransactionCode "]
                    } else if(doc.data().TransactionCode){
                        TransactionCode = doc.data().TransactionCode
                    }
                    Matches.push({
                        id: doc.id,
                        TransactionCode: TransactionCode,
                        PaymentStatus: doc.data().PaymentStatus,
                        PaymentType: doc.data().PaymentType,
                        Title: '',
                        FirstName: doc.data().FirstName,
                        LastName: doc.data().LastName,
                        House: doc.data().House,
                        PostCode: doc.data().PostCode,
                        AggregatedDonations: '',
                        SponsoredEvent: '',
                        Date: doc.data().Date,
                        TotalAmount: doc.data().Amount,
                        DonationAmount: doc.data().DonationAmount,
                        TransactionFee: doc.data().TransactionFee,
                        AdminContribution: doc.data().AdminContribution,
                        Time: doc.data().Time,
                        Type: doc.id.split('-')[1],
                        GiftAidAccepted: doc.data().GiftAidAccepted,
                        GiftAidType: doc.data().GiftAidType,
                        DonationType: doc.data().Title,
                        Project: doc.data().Project,
                        ProjectOption: doc.data().ProjectOption,
                        Reference: doc.data().MemberReference,
                        UserReportingID: doc.data().UserReportingID,
                        // Timestamp: doc.id.split('-')[0],
                        monthYear: doc.data().Date.split('/')[1]+'-'+doc.data().Date.split('/')[2],
                        // Date: doc.data().Date,
                        UserDisplayName: doc.data().FirebaseUserDisplayName,
                        UserEmail: doc.data().FirebaseUserEmail,
                        easyDonateVersion: doc.data().easyDonateVersion,
                        // data: doc.data(),

                      },
                    );
                  });
                  const groupedByMonth = _.groupBy(Matches, transition => transition.monthYear) 
                  // Matches = groupBy(Matches, "month");
                  this.setState({ Matches });
                  this.setState({ groupedByMonth : groupedByMonth });
                  // console.log(groupedByMonth)
                  // let group = lodash.groupBy(Matches, month.year)
                });
            // console.log(markers);
        } else {
            // doc.data() will be undefined in this case
            this.setState({ data: null });
            console.log("No such document!");
        }
    }).catch(function (error) {
        this.setState({ data: null });
        console.log("Error getting document:", error);
    });
  }
  

  componentWillUnmount(){

  }

  render() {
    // console.log(this.state.groupedByMonth)
    var tifs = this.state.groupedByMonth;
    // var g = this.state.Matches;
    // console.log(g)
    tifs = (
      _.chain(tifs)
        // Group the elements of Array based on `color` property
        // `key` is group's name (color), `value` is the array of objects
        .map((value, key) => ({ monthYear: key, data: value }))
        .value()
    );
    // console.log(tifs);

    tifs.reverse();
    return (
      <div id="against">
        {/* <h1>Download Reports</h1>
        {tifs.map(v => {
          return (
            <li key={v.monthYear}>
              Month: {v.monthYear} - 
              <CsvDownload data={v.data} filename={"easyDonate-Transactions-"+v.monthYear+".csv"} />
            </li>
          );
        })} */}
        <Container fixed>
          <h1>Donation Reports</h1>
          <p>These reports can be downloaded in a Gift Aid ready CSV format.</p>
          <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell align="right">Total Transactions</TableCell>
                    <TableCell align="right">Total Raised</TableCell>
                    <TableCell align="right">GASDS</TableCell>
                    <TableCell align="right">Gift Aid</TableCell>
                    {this.state.StudentFeesEnabled ? 
                    <TableCell align="right">Student Fees</TableCell> : null }
                    {this.state.MemberFeesEnabled ? 
                    <TableCell align="right">Membership Fees</TableCell> : null }
                    <TableCell align="right">All Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tifs.map(v => 
                  (
                    <TableRow key={v.monthYear}>
                      <TableCell component="th" scope="row">
                        {v.monthYear}
                      </TableCell>
                      <TableCell align="right">{v.data.length}</TableCell>
                      <TableCell align="right">£
                      {
                        // v.data.map((i => {
                        //   return(<p key={i.id}>{i.Amount}</p>);
                        // }))
                        

                        v.data.map((i => i.TotalAmount))
                        .reduce((acc, curr) => acc + curr, 0).toFixed(2)
                      }
                      </TableCell>
                      <TableCell align="right">
                        <CsvDownload data={v.data.filter(pilot => pilot.Type === "GASDS")} filename={"easyDonate-GASDS-Transactions-"+this.state.CharityID+"-"+v.monthYear+".csv"} >GASDS</CsvDownload>
                      </TableCell>
                      <TableCell align="right">
                        <CsvDownload data={v.data.filter(pilot => pilot.Type === "Gift Aid")} filename={"easyDonate-GiftAid-Transactions-"+this.state.CharityID+"-"+v.monthYear+".csv"} >Gift Aid</CsvDownload>
                      </TableCell>
                      {this.state.StudentFeesEnabled ? 
                      <TableCell align="right">
                        <CsvDownload data={v.data.filter(pilot => pilot.Type === "StudentFee")} filename={"easyDonate-StudentFee-Transactions-"+this.state.CharityID+"-"+v.monthYear+".csv"} >Student</CsvDownload>
                      </TableCell>
                      : null }
                      {this.state.MemberFeesEnabled ? 
                      <TableCell align="right">
                        <CsvDownload data={v.data.filter(pilot => pilot.Type.includes('Membership'))} filename={"easyDonate-Membership-Transactions-"+this.state.CharityID+"-"+v.monthYear+".csv"} >Membership</CsvDownload>
                      </TableCell>
                      : null }
                      <TableCell align="right">
                        <CsvDownload data={v.data} filename={"easyDonate-Transactions-"+this.state.CharityID+"-"+v.monthYear+".csv"} >All</CsvDownload>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </Container>
      </div>
      
    );
  }

}

export default Transactions;
