import React, { Component } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../actions";
import { myFirebase } from "../firebase/firebase";
import { db, database } from "../firebase/firebase";
import Grid from '@material-ui/core/Grid';
import logo from '../logo-alt.png';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';



class Header extends Component {
  constructor(props){
    super(props);
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];


    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();
  }
  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch(logoutUser());
  };

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    this.warnTimeout = setTimeout(this.warn, 16 * 1000);

    this.logoutTimeout = setTimeout(this.logout, 60 * 30 * 1000);
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  warn() {
    // alert("You will be logged out automatically in 1 minute.");
  }

  state={

  };

  logout() {
    // Send a logout request to the API
    console.log("Sending a logout request to the API...");
    this.handleLogout();
    // this.destroy(); // Cleanup
  }

  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  componentDidMount(){
    const self = this;
    let user = myFirebase.auth().currentUser; 
    const docRef = db.collection('Users').doc(user.uid);
    docRef.get().then((doc) => {
      if (doc.exists) {
          let CharityID = doc.data().CharityID;
          self.setState({
            CharityID: doc.data().CharityID,
            CharityID2: doc.data().CharityID2,
            Online: doc.data().Online
          });
      } else {
          self.setState({ data: null });
          console.log("No such document!");
      }
    }).catch(function (error) {
      self.setState({ data: null });
        console.log("Error getting document:", error);
    });
  }

  componentWillUnmount(){
    
  }

  render() {
    const { isLoggingOut, logoutError } = this.props;
    let user = myFirebase.auth().currentUser;    
    return (
      <div>
        <header>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={5}>
            <Grid item xl={1} lg={1} xs={3} sm={2}>
              <img src={logo} width="100%"/>
            </Grid>
            <Grid item xs={6} >
              <Box display="flex" justifyContent="flex-end">
                <Button disableElevation size="medium" style={{ marginRight: '12px'}} href="/">
                  Home
                </Button>
                {this.state.CharityID ?
                  <Button disableElevation size="medium" style={{ marginRight: '12px'}} href="/reports">
                    Reports
                  </Button>
                : null}
                {this.state.Online ? <Button disableElevation size="medium" style={{ marginRight: '12px'}} href="/online-reports">Online Reports</Button> : null}
                {this.state.CharityID ?
                  <Button disableElevation size="medium" style={{ marginRight: '12px'}} href="/settings">
                    Settings
                  </Button>
                : null}
                <Button disableElevation variant="contained" size="medium" style={{background: '#0d5d46', color: '#fff'}} onClick={this.handleLogout}>
                  Logout
                </Button>
              </Box>
            </Grid>
          </Grid>
        </header>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError
  };
}
export default connect(mapStateToProps)(Header);
