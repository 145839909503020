import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { logoutUser } from "../actions";
import { myFirebase } from "../firebase/firebase";
import { db, database } from "../firebase/firebase";
// import UserData from "../components/UserData";
import Transactions from "../components/Transactions";
import Settings from "../components/Settings";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import configureStore from "../configureStore";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import logo from '../logo-alt.png';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Header from './Header';

const store = configureStore();

function Uid(){
  let user = myFirebase.auth().currentUser;    
  return user.uid;
}

class Home extends Component {
  constructor(props){
    super(props);
  }

  state={

  };

  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch(logoutUser());
  };

  componentDidMount(){
    const self = this;
    let user = myFirebase.auth().currentUser; 
    const docRef = db.collection('Users').doc(user.uid);
    docRef.get().then((doc) => {
      if (doc.exists) {
          let CharityID = doc.data().CharityID;
          self.setState({
            CharityID: doc.data().CharityID,
            CharityID2: doc.data().CharityID2,
            Online: doc.data().Online
          });
          db.collection('LicencedAccounts')
          .doc(CharityID)
          .get()
          .then(snapshot => {
            console.log(snapshot.data().MemberFeesEnabled)
            self.setState({
              CharityName: snapshot.data().CharityName,
              LicenceType: snapshot.data().LicenceType,
              LicenceExpiry: snapshot.data().LicenceExpiry,
              MemberFeesEnabled: snapshot.data().MemberFeesEnabled,
              StudentFeesEnabled: snapshot.data().StudentFeesEnabled,
              MultipleDonationTypes: snapshot.data().MultipleDonationTypes,
              LicenceActive: snapshot.data().LicenceActive
            })
          });
      } else {
          self.setState({ data: null });
          console.log("No such document!");
      }
    }).catch(function (error) {
      self.setState({ data: null });
        console.log("Error getting document:", error);
    });
  }

  render() {
    const { isLoggingOut, logoutError } = this.props;
    let user = myFirebase.auth().currentUser;    
    const uid = db.collection("Users").doc(user.uid);
    const email = myFirebase.auth().currentUser && myFirebase.auth().currentUser.email;
    return (
      <div>
        <Header />
        <Container fixed>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <Box>
                <h1>Welcome to your easyDonate web portal</h1>
                <p>We're still making improvements to the portal but you can now access your reports and edit your account/kiosk settings online.</p>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box style={{textAlign: 'right'}}>
                <h2>Your Account</h2>
                <p><b>Charity:</b> {this.state.CharityName}<br></br>
                <b>Merchant ID:</b> {this.state.CharityID}<br></br>
                {this.state.CharityID2
                  ? <div><b>Merchant ID 2: </b>{this.state.CharityID2}<br></br></div>
                  : null
                }
                {this.state.Online
                  ? <div><b>Online: </b>{this.state.Online}<br></br></div>
                  : null
                }
                <b>Username:</b> {email.split('@').shift()} <br></br>
                {this.state.MemberFeesEnabled
                  ? <div><b>Member Fees: </b>Enabled<br></br></div>
                  : <div><b>Member Fees: </b>Disabled<br></br></div>
                }
                {this.state.StudentFeesEnabled
                  ? <div><b>Student Fees: </b>Enabled<br></br></div>
                  : <div><b>Student Fees: </b>Disabled<br></br></div>
                }
                {this.state.MultipleDonationTypes
                  ? <div><b>Multiple Donation Types: </b>Enabled<br></br></div>
                  : <div><b>Multiple Donation Types: </b>Disabled<br></br></div>
                }
                </p>
                <p style={{color: '#9f9f9f'}}>
                  License Type: {this.state.LicenceType}<br></br>
                  {console.log(this.state)}
                  {this.state.LicenceActive
                    ? <div>License Active: Active<br></br></div>
                    : <div>License Active: Disabled<br></br></div>
                  }
                  Licence Expiry: {this.state.LicenceExpiry}<br></br>
                  User ID: {uid.id}
                </p>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            {this.state.CharityID ?
            <Grid item xs={6}>
              <Link to="/reports" style={{textDecoration: 'none'}}>
                <Box style={{background: '#289a7c', padding: '0.5rem 2rem 1rem', borderRadius: '4px', color: '#fff'}}>
                  <h2>Donation Reports</h2>
                  <p>Click to view your monthly donation reports</p>
                </Box>
              </Link>
            </Grid>
            : null
            }
            {this.state.CharityID ?
            <Grid item xs={6}>
              <Link to="/settings" style={{textDecoration: 'none'}}>
                <Box style={{background: '#0d5d46', padding: '0.5rem 2rem 1rem', borderRadius: '4px', color: '#fff'}}>
                  <h2>Kiosk/App Settings</h2>
                  <p>Click to edit your kiosk/app settings.</p>
                </Box>
              </Link>
            </Grid>
            : null}
            {this.state.Online ?
            <Grid item xs={6}>
              <Link to="/online-reports" style={{textDecoration: 'none'}}>
                <Box style={{background: '#165442', padding: '0.5rem 2rem 1rem', borderRadius: '4px', color: '#fff'}}>
                  <h2>Online Donation Reports</h2>
                  <p>Click to view your monthly donation reports.</p>
                </Box>
              </Link>
            </Grid>
            : null}
          </Grid>
        </Container>
    
        <CssBaseline />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError
  };
}
export default connect(mapStateToProps)(Home);
