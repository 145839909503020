import React from "react";

import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";

import App from "./App";
import Settings from "./components/Settings";
import Reports from "./components/Reports";
import ProtectedRoute from "./components/ProtectedRoute";
import configureStore from "./configureStore";

const store = configureStore();

function Root() {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
}

export default Root;
