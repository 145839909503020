import React, { Component } from "react";
import { myFirebase } from "../firebase/firebase";
import { db } from "../firebase/firebase";
import { CSVLink } from "react-csv";
import CsvDownload from 'react-json-to-csv';
import { Link } from 'react-router-dom';
import EditableList from 'react-list-editable';
import 'react-list-editable/lib/react-list-editable.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Transactions from "../components/Transactions";
import Header from './Header';
import CssBaseline from '@material-ui/core/CssBaseline';



class Reports extends Component{
  constructor(props){
    super(props);
  }
  state = {};
  onLoad = (e) => {

  }

  componentDidMount(){}

  componentWillUnmount(){

  }

  render() {
    return (
      <div>
        <Header />
        <Transactions />
        <CssBaseline />
      </div>
      
    );
  }

}

export default Reports;
